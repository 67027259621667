import React, { useCallback, useMemo, useEffect, useState } from "react";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { useField, useFormikContext } from "formik";
import { Box, FormHelperText, Grid, LinearProgress } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import {
	DndContext,
	DragOverlay,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors
} from "@dnd-kit/core";
import {
	SortableContext,
	sortableKeyboardCoordinates,
	arrayMove
} from "@dnd-kit/sortable";
import Portal from "@mui/material/Portal";

import ExpressionOperators from "./ExpressionOperators";
import ExpressionNode from "./ExpressionNode";
import ExpressionLeafTable from "./ExpressionLeafTable";
import SearchTextField from "../SearchTextField/SearchTextField";
import Operators from "./Operators";
import { Operations, useResourceClient } from "../../hooks/useResource";
import { getComparator, stableSort } from "../../utils/sortUtil";
import { getExpressionParser } from "./helpers";
import ExpressionDropzone from "./ExpressionDropzone";
import {
	SORTABLE_ID_KEY,
	DROPZONE_SORTABLE_ID,
	OPERATORS_SORTABLE_ID,
	LEAF_CONTAINER_SORTABLE_ID
} from "./constants";
import FormFieldSwitch from "../FormFieldSwitch/FormFieldSwitch";
import FormField from "../CrudForm/FormField";
import lc from "./localeContent";
import buildExpressionBuilderValidator from "./expressionBuilderValidator";
import commonLocaleContent from "../../screens/commonLocaleContent";

const FIELD_ENABLED_KEY = "enabled";

const addSortableIdToItems = items =>
	items.map(item => ({ ...item, [SORTABLE_ID_KEY]: uuidv4() }));

const getSortableDictionary = sortableContainers => {
	return Object.entries(sortableContainers).reduce(
		(dictionary, [, container]) => ({
			...dictionary,
			...container.reduce(
				(agg, item) => ({
					...agg,
					[item[SORTABLE_ID_KEY]]: item
				}),
				{}
			)
		}),
		{}
	);
};
const DRAGGABLE_OPERATOR_OPTIONS = [
	Operators.AND,
	Operators.OR,
	Operators.NOT,
	Operators.PARENTHESIS
];

const getDefaultOperators = (suppressOperators = []) =>
	addSortableIdToItems(
		DRAGGABLE_OPERATOR_OPTIONS.filter(
			operator => !suppressOperators.includes(operator)
		)
	);

// TODO: This is definitely missing other html conversions. We should look into a library for this
const safeReplaceHtml = value =>
	value
		.replace(/&gt;/gi, ">")
		.replace(/&amp;/gi, "&")
		.replace(/&#39;/gi, "'");

const splitParenthesis = expression =>
	expression.reduce((agg, expressionNode) => {
		if (expressionNode.nodeType === Operators.PARENTHESIS.nodeType) {
			return [
				...agg,
				...addSortableIdToItems([Operators.LEFT_PAREN, Operators.RIGHT_PAREN])
			];
		}
		return [...agg, expressionNode];
	}, []);

export const transformInitData = (data, fieldName, fieldConfig) => {
	const fieldData = data[fieldName];
	const {
		leafExpressionNodeRepresentation: { nodeType: leafNodeType, idKey },
		suppressOperators,
		enableFieldLabel,
		leafOptionsConfig = {},
		customLeafCreation
	} = fieldConfig.fieldTypeOptions || {};

	const { existingLeafField, leafOptionIdKey } = leafOptionsConfig;

	let fieldOptions = [];
	if (existingLeafField) {
		fieldOptions = (data[existingLeafField] || []).map(value => ({
			...value,
			label: safeReplaceHtml(value.name)
		}));
	}

	// Use recursion to drill down into the expression and return a flat array of blocks
	function getExpressionBlockRepresentation(expressionNode, isRootNode) {
		let blockRepresentation = [];
		switch (expressionNode.nodeType) {
			case Operators.AND.nodeType:
			case Operators.OR.nodeType:
				blockRepresentation = blockRepresentation.concat(
					getExpressionBlockRepresentation(expressionNode.nodes.shift())
				);
				expressionNode.nodes.forEach(node => {
					blockRepresentation.push({ ...Operators[expressionNode.nodeType] });
					blockRepresentation = blockRepresentation.concat(
						getExpressionBlockRepresentation(node)
					);
				});

				if (!isRootNode) {
					blockRepresentation.unshift(Operators.LEFT_PAREN);
					blockRepresentation.push(Operators.RIGHT_PAREN);
				}
				break;
			case Operators.NOT.nodeType:
				blockRepresentation.push({ ...Operators.NOT });
				blockRepresentation = blockRepresentation.concat(
					getExpressionBlockRepresentation(expressionNode.nodes[0])
				);
				break;
			// Leaf
			case leafNodeType:
				if (customLeafCreation) {
					blockRepresentation.push(expressionNode);
					break;
				}
				blockRepresentation.push({
					...fieldOptions.find(
						({ [leafOptionIdKey]: id }) => id === expressionNode[idKey]
					),
					nodeType: leafNodeType
				});
				break;
			default:
				break;
		}
		return blockRepresentation;
	}
	let expression = [];
	if (fieldData) {
		// Convert gson to javascript object
		const decodedJsonExpression = fieldData.replace(/&quot;/g, '"');
		const decodedFieldValue = JSON.parse(decodedJsonExpression);
		let transformedFieldValue = decodedFieldValue;

		if (customLeafCreation) {
			transformedFieldValue = customLeafCreation.transformExpressionOnInit(
				decodedFieldValue
			);
		}

		expression = addSortableIdToItems(
			getExpressionBlockRepresentation(transformedFieldValue, true)
		);
	}

	const operators = getDefaultOperators(suppressOperators);

	const initialState = {
		activeItemSortableId: null,
		sortableDictionary: getSortableDictionary({ expression, operators }),
		operators,
		expression
	};
	if (enableFieldLabel) {
		initialState[FIELD_ENABLED_KEY] = Boolean(fieldData);
	}
	return initialState;
};

const getExpressionNodeArray = (
	fieldData,
	leafOptionIdKey,
	leafExpressionNodeRepresentation,
	customLeafCreation
) => {
	const { nodeType: leafNodeType, idKey } = leafExpressionNodeRepresentation;
	return fieldData.map(
		({ nodeType: itemNodeType, [leafOptionIdKey]: leafId, ...rest }) => {
			let expressionNodeRepresentation = { nodeType: itemNodeType };
			if (leafNodeType === itemNodeType) {
				if (customLeafCreation) {
					expressionNodeRepresentation = {
						...expressionNodeRepresentation,
						...rest
					};
				} else {
					expressionNodeRepresentation[idKey] = leafId;
				}
			}
			return expressionNodeRepresentation;
		}
	);
};

export const transformSubmitData = (
	data,
	fieldName,
	resourceId,
	fieldConfig
) => {
	if (data[FIELD_ENABLED_KEY] === false) return null;
	const {
		leafExpressionNodeRepresentation,
		leafOptionsConfig = {},
		customLeafCreation
	} = fieldConfig.fieldTypeOptions || {};

	const { nodeType } = leafExpressionNodeRepresentation;
	const { transformExpressionOnSubmit } = customLeafCreation || {};
	const { leafOptionIdKey } = leafOptionsConfig;

	const nodes = getExpressionNodeArray(
		data.expression,
		leafOptionIdKey,
		leafExpressionNodeRepresentation,
		customLeafCreation
	);

	let expression = getExpressionParser(nodeType).getTreeJson(nodes);
	if (transformExpressionOnSubmit) {
		expression = transformExpressionOnSubmit(expression);
	}
	return { [fieldName]: JSON.stringify(expression) };
};

const getCloneInsertionIndex = (overId, expressionIds) => {
	const overIndex = expressionIds.indexOf(overId);
	return overIndex === -1 ? expressionIds.length : overIndex;
};

const isBlockLevelError = expressionLevelError =>
	expressionLevelError && typeof expressionLevelError !== "string";

/**
 * Our approach to sorting a variable-width list here is based on answers by the library's author in github issues:
 * https://github.com/clauderic/dnd-kit/issues/117#issuecomment-789863258
 * https://github.com/clauderic/dnd-kit/issues/44
 * both of which refer to this codesandbox https://codesandbox.io/s/test-dnd-kit-forked-rhsq9?file=/src/App.js
 */
function FormFieldExpressionBuilder(props) {
	const {
		name,
		fieldTypeOptions: {
			leafExpressionNodeRepresentation,
			emptyExpressionWarning,
			suppressOperators,
			enableFieldLabel,
			enableFieldTooltip,
			leafOptionsConfig,
			customLeafCreation,
			RuleVisualizer
		}
	} = props;

	const { leafOptionsEndpoint, leafTableColumnsConfig } =
		leafOptionsConfig || {};

	const [field, meta, helpers] = useField(name);
	// For some reason useField()'s meta.touched becomes a boolean on form submit, so we use useFormikContext()'s touched object that shows nested key's touched status
	const { touched: formikTouched } = useFormikContext();

	const {
		activeItemSortableId,
		operators,
		expression,
		sortableDictionary,
		[FIELD_ENABLED_KEY]: fieldEnabled
	} = field.value || {};

	// We only update the formik expression value when we stop dragging to avoid expensive calculations while dragging that seem to lead to rendering performance issues
	const [displayedExpression, setDisplayedExpression] = useState(expression);
	// Keep displayedExpression synced with the formik expression
	useEffect(() => setDisplayedExpression(expression), [expression]);

	const displayedExpressionIds = useMemo(
		() => displayedExpression.map(item => item[SORTABLE_ID_KEY]),
		[displayedExpression]
	);
	const operatorSortableIds = useMemo(
		() => operators.map(item => item[SORTABLE_ID_KEY]),
		[operators]
	);

	// Query Leaf Options and setup sortability
	const [
		leafOptions,
		requestLeafOptionsError,
		isLeafOptionsLoading,
		reloadLeafOptions
	] = useResourceClient(leafOptionsEndpoint, Operations.LIST);

	const [searchQuery, setSearchQuery] = useState("");
	useEffect(() => {
		reloadLeafOptions({ requestParams: { searchString: searchQuery } });
	}, [searchQuery, reloadLeafOptions]);

	// Error handling
	let expressionLevelError = meta.error?.expression;
	// Handle API errors
	if (meta.error && typeof meta.error === "string") {
		expressionLevelError = meta.error;
	}
	let showError = Boolean(
		formikTouched[name]?.expression && expressionLevelError
	);
	if (!isLeafOptionsLoading && requestLeafOptionsError) {
		showError = true;
		expressionLevelError =
			commonLocaleContent.UNABLE_TO_RETRIEVE_OPTIONS_WARNING;
	}

	let blockLevelError;
	if (isBlockLevelError(expressionLevelError)) {
		blockLevelError = expressionLevelError;
		expressionLevelError = lc.VALIDATION_MESSAGES.BLOCK_ERRORS;
	}

	const [transformedLeafOptions, setTransformedLeafOptions] = useState([]);
	useEffect(() => {
		setTransformedLeafOptions(
			stableSort(
				(leafOptions || []).map(leafOption => ({
					...leafOption,
					name: safeReplaceHtml(leafOption.name),
					[SORTABLE_ID_KEY]: uuidv4(),
					nodeType: leafExpressionNodeRepresentation.nodeType
				})),
				getComparator("asc", "name")
			)
		);
	}, [leafOptions, leafExpressionNodeRepresentation]);

	const leafOptionsSortableDictionary = useMemo(
		() =>
			transformedLeafOptions.reduce(
				(agg, option) => ({
					...agg,
					[option[SORTABLE_ID_KEY]]: option
				}),
				{}
			),
		[transformedLeafOptions]
	);

	const setActiveItemSortableId = useCallback(
		newId => helpers.setValue({ ...field.value, activeItemSortableId: newId }),
		[field.value, helpers]
	);

	const setClonedItemSortableId = useCallback(
		newId =>
			helpers.setValue({
				...field.value,
				activeItemSortableId: newId,
				cloneCandidateId: newId
			}),
		[field.value, helpers]
	);

	const moveDropzoneItem = useCallback(
		(oldIndex, newIndex) =>
			setDisplayedExpression(
				arrayMove(displayedExpression, oldIndex, newIndex)
			),
		[displayedExpression]
	);

	const cloneItemToDropzone = useCallback(
		(item, insertionIndex) => {
			const newExpression = [...displayedExpression];
			newExpression.splice(insertionIndex, 0, item);
			setDisplayedExpression(newExpression);
		},
		[displayedExpression]
	);

	const removeExpressionNode = useCallback(
		deleteSortableId => () => {
			helpers.setValue({
				...field.value,
				expression: field.value.expression.filter(
					({ [SORTABLE_ID_KEY]: sortableId }) => sortableId !== deleteSortableId
				)
			});
		},
		[helpers, field.value]
	);

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates
		})
	);

	const findContainer = useCallback(
		id => {
			if (id === DROPZONE_SORTABLE_ID) {
				return DROPZONE_SORTABLE_ID;
			}
			// Needs to run first to allow cloning
			if (displayedExpressionIds.includes(id)) {
				return DROPZONE_SORTABLE_ID;
			}
			if (operatorSortableIds.includes(id)) {
				return OPERATORS_SORTABLE_ID;
			}
			if (leafOptionsSortableDictionary[id]) {
				return LEAF_CONTAINER_SORTABLE_ID;
			}
			return null;
		},
		[displayedExpressionIds, operatorSortableIds, leafOptionsSortableDictionary]
	);

	const handleDragOver = event => {
		const { active, over } = event;

		if (over && active.id !== over.id) {
			const overContainer = findContainer(over.id);
			const activeContainer = findContainer(active.id);

			// Handle drags that start and end in the dropzone
			if (
				activeContainer === DROPZONE_SORTABLE_ID &&
				overContainer === DROPZONE_SORTABLE_ID
			) {
				const oldIndex = displayedExpressionIds.indexOf(active.id);
				const newIndex = displayedExpressionIds.indexOf(over.id);
				moveDropzoneItem(oldIndex, newIndex);
			}

			// Handle drags that start in the operators and end in the dropzone
			if (
				activeContainer === OPERATORS_SORTABLE_ID &&
				overContainer === DROPZONE_SORTABLE_ID
			) {
				// Clone it
				cloneItemToDropzone(
					sortableDictionary[active.id],
					getCloneInsertionIndex(over.id, displayedExpressionIds)
				);
			}

			// Handle drags that start in the leaf options and end in the dropzone
			if (
				activeContainer === LEAF_CONTAINER_SORTABLE_ID &&
				overContainer === DROPZONE_SORTABLE_ID
			) {
				// Clone it
				cloneItemToDropzone(
					leafOptionsSortableDictionary[active.id],
					getCloneInsertionIndex(over.id, displayedExpressionIds)
				);
			}
		}
		return null;
	};

	const handleDragStart = event => {
		const { active } = event;
		const activeContainer = findContainer(active.id);
		if (activeContainer !== DROPZONE_SORTABLE_ID) {
			setClonedItemSortableId(active.id);
		} else {
			setActiveItemSortableId(active.id);
		}
	};

	const endDrag = useCallback(
		event => {
			const { over, active } = event;
			const overContainer = findContainer(over?.id);
			const activeContainer = findContainer(active.id);

			// create new operators to clone from and recalculate the sortableDictionary
			const newOperators = getDefaultOperators(suppressOperators);
			// split any cloned parenthesis block into a pair of left and right parenthesis
			let newExpression = splitParenthesis(displayedExpression);
			if (
				activeContainer === DROPZONE_SORTABLE_ID &&
				overContainer !== DROPZONE_SORTABLE_ID &&
				field.value.cloneCandidateId
			) {
				// Removing item
				newExpression = newExpression.filter(
					({ [SORTABLE_ID_KEY]: sortableId }) =>
						sortableId !== field.value.cloneCandidateId
				);
			}
			const newSortableDictionary = getSortableDictionary({
				expression: newExpression,
				operators: newOperators
			});
			helpers.setValue({
				...field.value,
				cloneCandidateId: null,
				activeItemSortableId: null,
				expression: newExpression,
				operators: newOperators,
				sortableDictionary: newSortableDictionary
			});
			setTransformedLeafOptions(existing =>
				existing.map(item => ({ ...item, [SORTABLE_ID_KEY]: uuidv4() }))
			);
		},
		[
			field.value,
			helpers,
			findContainer,
			suppressOperators,
			displayedExpression
		]
	);

	const handleSearchQueryChange = newSearchQuery => {
		setSearchQuery(newSearchQuery);
	};

	const activeItem =
		sortableDictionary[activeItemSortableId] ||
		leafOptionsSortableDictionary[activeItemSortableId] ||
		{};

	const canEnableField = Boolean(enableFieldLabel);
	const showContent = !canEnableField || (canEnableField && fieldEnabled);

	// Custom Leaf Creation/Modification
	const [editCustomLeafInitData, setEditCustomLeafInitData] = useState(null);
	const onCustomLeafModalSubmission = useCallback(
		formData => {
			let newExpressionValue;
			// If we're editing a leaf block, we need to replace it at its current index position in the expression
			if (editCustomLeafInitData) {
				newExpressionValue = field.value.expression.map(item => {
					const { sortableId } = item;
					if (sortableId === editCustomLeafInitData.sortableId) {
						return {
							...item,
							...formData
						};
					}
					return item;
				});
				setEditCustomLeafInitData(null);
			} else {
				// Else we create a new leaf node and add it to the end of the expression
				newExpressionValue = field.value.expression.concat(
					addSortableIdToItems([
						{
							...formData,
							nodeType: leafExpressionNodeRepresentation.nodeType
						}
					])
				);
			}
			helpers.setValue({
				...field.value,
				expression: newExpressionValue
			});
		},
		[
			field.value,
			helpers,
			leafExpressionNodeRepresentation.nodeType,
			editCustomLeafInitData
		]
	);

	return (
		<Grid container spacing={2} item xs={12}>
			<Grid item xs={12}>
				<Box sx={{ display: "flex", justifyContent: "space-between" }}>
					{canEnableField && (
						<FormField
							gridConfig={{ custom: true }}
							tooltip={enableFieldTooltip}
						>
							<Box>
								<FormFieldSwitch
									id={`${name}.${FIELD_ENABLED_KEY}`}
									name={`${name}.${FIELD_ENABLED_KEY}`}
									label={enableFieldLabel}
								/>
							</Box>
						</FormField>
					)}
					{showContent && customLeafCreation && (
						<customLeafCreation.Component
							onSubmit={onCustomLeafModalSubmission}
							editLeafInitData={editCustomLeafInitData}
							cancelEdit={() => setEditCustomLeafInitData(null)}
						/>
					)}
				</Box>
			</Grid>
			{showContent && (
				<>
					{leafOptionsConfig && (
						<Grid
							item
							lg={4}
							xs={12}
							sx={{ mb: !isLeafOptionsLoading ? 0.5 : undefined }}
						>
							<SearchTextField
								searchQueryValue={searchQuery}
								onSearchQueryValueChange={handleSearchQueryChange}
								fullWidth
							/>
						</Grid>
					)}

					{isLeafOptionsLoading && (
						<Grid item xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
							<LinearProgress />
						</Grid>
					)}
					<Grid item xs={12} style={{ paddingTop: 0 }}>
						<DndContext
							sensors={sensors}
							onDragStart={handleDragStart}
							onDragOver={handleDragOver}
							onDragEnd={endDrag}
							onDragCancel={endDrag}
							autoScroll={false}
						>
							{leafOptionsConfig && (
								<ExpressionLeafTable
									sortableIdKey={SORTABLE_ID_KEY}
									leafOptions={transformedLeafOptions}
									columns={leafTableColumnsConfig}
								/>
							)}

							<SortableContext items={operatorSortableIds} strategy={() => {}}>
								<ExpressionOperators operatorOptions={operators} />
							</SortableContext>
							<SortableContext
								items={displayedExpressionIds}
								strategy={() => {}}
							>
								<ExpressionDropzone
									expression={displayedExpression}
									blockErrors={blockLevelError}
									removeExpressionNode={removeExpressionNode}
									onEdit={formData => () => {
										setEditCustomLeafInitData(formData);
									}}
									leafExpressionNodeRepresentation={
										leafExpressionNodeRepresentation
									}
									emptyExpressionWarning={emptyExpressionWarning}
									findContainer={findContainer}
									showError={showError}
									isActiveItem={Boolean(activeItemSortableId)}
								/>
							</SortableContext>
							<Portal container={document.body}>
								<DragOverlay>
									{activeItemSortableId ? (
										<ExpressionNode
											label={
												activeItem.label ||
												activeItem[leafExpressionNodeRepresentation.labelKey]
											}
											isLeafNode={
												activeItem.nodeType ===
												leafExpressionNodeRepresentation.nodeType
											}
											onDelete={() => {}}
											onEdit={
												customLeafCreation &&
												activeItem.nodeType ===
													leafExpressionNodeRepresentation.nodeType
													? () => {} // We pass an empty function to the drag overlay to render the icon without allowing anything to happen if it's clicked
													: null
											}
										/>
									) : null}
								</DragOverlay>
							</Portal>
						</DndContext>
						{showError && (
							<FormHelperText error>{expressionLevelError}</FormHelperText>
						)}
						{RuleVisualizer && <RuleVisualizer expression={expression} />}
					</Grid>
				</>
			)}
		</Grid>
	);
}

Yup.addMethod(
	Yup.object,
	"validExpressionLeaf",
	function addValidExpressionLeaf(expressionBuilderValidator) {
		return this.test(
			"validExpressionLeaf",
			"{default message}",
			function testValidExpressionLeaf(value) {
				const itemIndex = this.parent.findIndex(
					block => block[SORTABLE_ID_KEY] === value[SORTABLE_ID_KEY]
				);
				const priorItemIndex = itemIndex - 1 < 0 ? null : itemIndex - 1;
				const nextItemIndex =
					itemIndex + 1 > this.parent.length ? null : itemIndex + 1;
				const lookbackError = expressionBuilderValidator.getAdjacentBlockError(
					this.parent[priorItemIndex]?.nodeType,
					this.parent[itemIndex].nodeType
				);
				const lookaheadError = expressionBuilderValidator.getAdjacentBlockError(
					this.parent[itemIndex].nodeType,
					this.parent[nextItemIndex]?.nodeType
				);
				if (lookbackError || lookaheadError) {
					throw this.createError({
						path: this.path,
						message: lookbackError || lookaheadError
					});
				}

				return true;
			}
		);
	}
);

Yup.addMethod(Yup.array, "validExpression", function addValidExpression(
	expressionBuilderValidator
) {
	return this.test(
		"validExpression",
		"{default message}",
		function testValidExpression(expression) {
			const expressionError = expressionBuilderValidator.getExpressionLevelError(
				expression[0],
				expression[expression.length - 1]
			);
			if (expressionError) {
				throw this.createError({
					path: this.path,
					message: expressionError
				});
			}

			return true;
		}
	);
});

export const buildValidationSchema = ({ leafName, leafNodeKey, canEnable }) => {
	// Create a validator object using dynamic leaf information so we can pass a singleton into our validation functions
	const expressionBuilderValidator = buildExpressionBuilderValidator(
		leafName,
		leafNodeKey
	);

	// Base schema
	let expressionSchema = Yup.array()
		.of(Yup.object().validExpressionLeaf(expressionBuilderValidator))
		.validExpression(expressionBuilderValidator)
		.min(1, lc.VALIDATION_MESSAGES.AT_LEAST_ONE_LEAF(leafName));

	// If the field is not required, we need to dynamically require the validation
	if (canEnable) {
		expressionSchema = Yup.array().when(FIELD_ENABLED_KEY, {
			is: true,
			then: expressionSchema
		});
	}
	return Yup.object({
		expression: expressionSchema
	});
};

FormFieldExpressionBuilder.propTypes = {
	name: PropTypes.string.isRequired,
	fieldTypeOptions: PropTypes.shape({
		emptyExpressionWarning: PropTypes.string.isRequired,
		enableFieldLabel: PropTypes.string,
		enableFieldTooltip: PropTypes.string,
		leafExpressionNodeRepresentation: PropTypes.shape({
			nodeType: PropTypes.string.isRequired,
			labelKey: PropTypes.string.isRequired,
			idKey: PropTypes.string
		}),
		suppressOperators: PropTypes.arrayOf(PropTypes.shape()),
		leafOptionsConfig: PropTypes.shape({
			leafOptionsEndpoint: PropTypes.string,
			leafTableColumnsConfig: PropTypes.arrayOf(PropTypes.shape()),
			existingLeafField: PropTypes.string,
			leafOptionIdKey: PropTypes.string
		}),
		customLeafCreation: PropTypes.shape({
			Component: PropTypes.func.isRequired,
			transformExpressionOnInit: PropTypes.func,
			transformExpressionOnSubmit: PropTypes.func
		}),
		RuleVisualizer: PropTypes.func
	}).isRequired
};

export default FormFieldExpressionBuilder;
