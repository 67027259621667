import React from "react";
import * as Yup from "yup";
import CrudForm from "../../components/CrudForm/CrudForm";
import localeContent from "./localeContent";
import commonLocaleContent from "../commonLocaleContent";
import FieldTypes from "../../components/CrudForm/FieldTypes";
import commonFieldOptions from "../commonFieldOptions";
import {
	REPORTING_LIMIT_DIMENSIONS_RTB_OPTIONS,
	REPORTING_LIMIT_DIMENSIONS_NETWORK_OPTIONS,
	REPORTING_LIMIT_DIMENSIONS_CAMPAIGN_OPTIONS,
	REPORTING_LIMIT_DIMENSIONS_PUBLISHER_OPTIONS,
	PRIMARY_COLOR_OPTIONS
} from "./fieldOptions";
import {
	DEFAULT_BID_REQUEST_MULTIPLIER,
	DEFAULT_OPPORTUNITY_COST_MULTIPLIER
} from "../../config/constants";

const TABS_CONFIG = [
	{
		key: "SETTINGS",
		label: commonLocaleContent.TAB_LABEL_SETTINGS,
		fieldsConfig: [
			{
				key: "BASIC_SETTINGS",
				fields: [
					{
						name: "name",
						label: localeContent.COMPANY_NAME.LABEL,
						gridConfig: { md: 8 },
						isRequired: true
					},
					{
						name: "status",
						label: commonLocaleContent.FIELD_LABEL_STATUS,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							options: commonFieldOptions.mapEnumToFieldOptions(
								commonFieldOptions.STATUS_ENUM
							)
						},
						defaultValue: commonFieldOptions.STATUS_ENUM.active.value
					},
					{
						name: "companyDomain",
						label: localeContent.COMPANY_DOMAIN.LABEL,
						tooltip: localeContent.COMPANY_DOMAIN.TOOLTIP,
						gridConfig: { md: 6 },
						isRequired: true,
						validationSchema: Yup.string().validDomain(
							commonLocaleContent.INVALID_DOMAIN_WARNING
						)
					},
					{
						name: "confidential",
						label: localeContent.CONFIDENTIAL.LABEL,
						tooltip: localeContent.CONFIDENTIAL.TOOLTIP,
						gridConfig: { md: 3 },
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							useBooleanValues: true,
							options: [
								{
									value: true,
									label: commonLocaleContent.OPTION_LABEL_YES
								},
								{
									value: false,
									label: commonLocaleContent.OPTION_LABEL_NO
								}
							]
						},
						defaultValue: false
					},
					{
						name: "showHelpText",
						label: localeContent.SHOW_HELP_TEXT.LABEL,
						gridConfig: { md: 3 },
						fieldType: FieldTypes.SWITCH,
						defaultValue: true
					},
					{
						name: "privacyPolicyUrl",
						label: localeContent.PRIVACY_POLICY_URL.LABEL,
						gridConfig: { md: 6, lg: 4 },
						defaultValue: "https://www.column6.com/privacy-policy"
					},
					{
						name: "tcUrl",
						label: localeContent.TC_URL.LABEL,
						gridConfig: { md: 6, lg: 4 },
						defaultValue: "http://www.column6.com/terms-and-conditions"
					}
				]
			},
			{
				key: "DOMAIN_SETTINGS",
				permissionRequired: "VIEW_DOMAIN_SETTINGS",
				fields: [
					{
						name: "rootDomain",
						label: localeContent.ROOT_DOMAIN.LABEL,
						gridConfig: { md: 8 },
						defaultValue: "siprocalads.com",
						isRequired: true,
						validationSchema: Yup.string().validDomain(
							commonLocaleContent.INVALID_DOMAIN_WARNING
						)
					},
					{
						name: "arenaDomain",
						label: localeContent.ARENA_DOMAIN.LABEL,
						gridConfig: { md: 4, offset: { md: 4 } },
						defaultValue: "arena",
						isRequired: true
					},
					{
						name: "mobileDomain",
						label: localeContent.MOBILE_DOMAIN.LABEL,
						gridConfig: { md: 4 },
						defaultValue: "mobile",
						isRequired: true
					},
					{
						name: "eventDomain",
						label: localeContent.EVENT_DOMAIN.LABEL,
						gridConfig: { md: 4 },
						defaultValue: "event",
						isRequired: true
					},
					{
						name: "rtbDomain",
						label: localeContent.RTB_DOMAIN.LABEL,
						gridConfig: { md: 4 },
						defaultValue: "rtb",
						isRequired: true
					},
					{
						name: "cookieDomain",
						label: localeContent.COOKIE_DOMAIN.LABEL,
						gridConfig: { md: 4 },
						defaultValue: "cookie",
						isRequired: true
					},
					{
						name: "publisherDomain",
						label: localeContent.PUBLISHER_DOMAIN.LABEL,
						gridConfig: { md: 4 },
						defaultValue: "publisher",
						isRequired: true
					},
					{
						name: "manageDomain",
						label: localeContent.MANAGE_DOMAIN.LABEL,
						gridConfig: { md: 4 },
						defaultValue: "manage",
						isRequired: true
					},
					{
						name: "mediaDomain",
						label: localeContent.MEDIA_DOMAIN.LABEL,
						gridConfig: { md: 4 },
						defaultValue: "media",
						isRequired: true
					},
					{
						name: "cdnDomain",
						label: localeContent.CDN_DOMAIN.LABEL,
						gridConfig: { md: 4 },
						defaultValue: "cdn",
						isRequired: true
					}
				]
			},
			{
				key: "APPEARANCE",
				hideOnCreate: false,
				fields: [
					{
						name: "branding",
						gridConfig: { md: 4 },
						label: localeContent.PRIMARY_COLOR.LABEL,
						fieldType: FieldTypes.PRIMARY_COLOR,
						optionsConfig: {
							options: PRIMARY_COLOR_OPTIONS
						},
						isRequired: true,
						tooltip: localeContent.PRIMARY_COLOR.TOOLTIP
					},
					{
						name: "platformLogo",
						gridConfig: { md: 4, offset: { md: 8 } },
						fieldType: FieldTypes.IMAGE_UPLOAD,
						fieldTypeOptions: {
							acceptedTypes: ["svg", "png", "jpg", "gif"],
							buttonLabel: localeContent.PLATFORM_LOGO.BUTTON_LABEL,
							previewLabel: localeContent.PLATFORM_LOGO.PREVIEW_LABEL,
							ImagePath: "branding/logo"
						},
						hideOnCreate: true
					},
					{
						name: "emailLogo",
						gridConfig: { md: 4 },
						fieldType: FieldTypes.IMAGE_UPLOAD,
						fieldTypeOptions: {
							acceptedTypes: ["png", "gif"],
							buttonLabel: localeContent.EMAIL_LOGO.BUTTON_LABEL,
							previewLabel: localeContent.EMAIL_LOGO.PREVIEW_LABEL,
							ImagePath: "branding/email_logo"
						},
						hideOnCreate: true
					},
					{
						name: "favicon",
						gridConfig: { md: 4 },
						fieldType: FieldTypes.IMAGE_UPLOAD,
						fieldTypeOptions: {
							acceptedTypes: ["ico", "svg", "png", "jpg", "gif"],
							buttonLabel: localeContent.FAVICON.BUTTON_LABEL,
							previewLabel: localeContent.FAVICON.PREVIEW_LABEL,
							ImagePath: "branding/favicon",
							ImageHeight: "",
							ImageWidth: ""
						},
						hideOnCreate: true
					}
				]
			},
			{
				key: "EMAILS",
				fields: [
					{
						name: "supportEmail",
						label: localeContent.SUPPORT_EMAIL.LABEL,
						gridConfig: { md: 6 },
						fieldType: FieldTypes.EMAIL,
						defaultValue: "support@column6.com",
						isRequired: true
					},
					{
						name: "reportingEmail",
						label: localeContent.REPORTING_EMAIL.LABEL,
						gridConfig: { md: 6 },
						fieldType: FieldTypes.EMAIL,
						defaultValue: "reporting@column6.com",
						isRequired: true
					}
				]
			},
			{
				key: "CLONE_ROLES",
				hideOnCreate: true,
				fields: [
					{
						name: "cloneRoles",
						fieldType: FieldTypes.CLONE_ROLE
					}
				]
			}
		]
	},
	{
		key: "DEFAULTS",
		label: commonLocaleContent.TAB_LABEL_DEFAULTS,
		fieldsConfig: [
			{
				key: "DIMENSIONS DEFAULTS",
				fields: [
					{
						name: "reportingLimitRtbDimensions",
						label: localeContent.REPORTING_LIMIT_DIMENSIONS.LABEL({
							type: localeContent.RTB_LABEL
						}),
						tooltip: localeContent.REPORTING_LIMIT_DIMENSIONS.TOOLTIP,
						gridConfig: { md: 6 },
						fieldType: FieldTypes.MULTISELECT,
						optionsConfig: {
							options: [...REPORTING_LIMIT_DIMENSIONS_RTB_OPTIONS]
						},
						defaultValue: ["appBundle", "appName", "domain", "contentgenre"]
					},
					{
						name: "reportingLimitRtbDimensionsRange",
						label: localeContent.REPORTING_LIMIT_DIMENSIONS.RANGE_LABEL({
							type: localeContent.RTB_LABEL
						}),
						gridConfig: { md: 5 },
						fieldType: FieldTypes.NUMBER,
						fieldTypeOptions: {
							endAdornmentText: "days"
						},
						defaultValue: 7,
						isRequired: true
					},
					{
						name: "reportingLimitNetworkDimensions",
						label: localeContent.REPORTING_LIMIT_DIMENSIONS.LABEL({
							type: localeContent.NETWORK_LABEL
						}),
						tooltip: localeContent.REPORTING_LIMIT_DIMENSIONS.TOOLTIP,
						gridConfig: { md: 6 },
						fieldType: FieldTypes.MULTISELECT,
						optionsConfig: {
							options: [...REPORTING_LIMIT_DIMENSIONS_NETWORK_OPTIONS]
						},
						defaultValue: ["appBundle", "appName", "domain", "contentgenre"]
					},
					{
						name: "reportingLimitNetworkDimensionsRange",
						label: localeContent.REPORTING_LIMIT_DIMENSIONS.RANGE_LABEL({
							type: localeContent.NETWORK_LABEL
						}),
						gridConfig: { md: 5 },
						fieldType: FieldTypes.NUMBER,
						fieldTypeOptions: {
							endAdornmentText: "days"
						},
						defaultValue: 7,
						isRequired: true
					},
					{
						name: "reportingLimitCampaignDimensions",
						label: localeContent.REPORTING_LIMIT_DIMENSIONS.LABEL({
							type: localeContent.CAMPAIGN_LABEL
						}),
						tooltip: localeContent.REPORTING_LIMIT_DIMENSIONS.TOOLTIP,
						gridConfig: { md: 6 },
						fieldType: FieldTypes.MULTISELECT,
						optionsConfig: {
							options: [...REPORTING_LIMIT_DIMENSIONS_CAMPAIGN_OPTIONS]
						},
						defaultValue: ["appBundle", "appName", "domain", "contentgenre"]
					},
					{
						name: "reportingLimitCampaignDimensionsRange",
						label: localeContent.REPORTING_LIMIT_DIMENSIONS.RANGE_LABEL({
							type: localeContent.CAMPAIGN_LABEL
						}),
						gridConfig: { md: 5 },
						fieldType: FieldTypes.NUMBER,
						fieldTypeOptions: {
							endAdornmentText: "days"
						},
						defaultValue: 7,
						isRequired: true
					},
					{
						name: "reportingLimitPublisherDimensions",
						label: localeContent.REPORTING_LIMIT_DIMENSIONS.LABEL({
							type: localeContent.PUBLISHER_LABEL
						}),
						tooltip: localeContent.REPORTING_LIMIT_DIMENSIONS.TOOLTIP,
						gridConfig: { md: 6 },
						fieldType: FieldTypes.MULTISELECT,
						optionsConfig: {
							options: [...REPORTING_LIMIT_DIMENSIONS_PUBLISHER_OPTIONS]
						},
						defaultValue: ["appBundle", "appName", "domain", "contentgenre"]
					},
					{
						name: "reportingLimitPublisherDimensionsRange",
						label: localeContent.REPORTING_LIMIT_DIMENSIONS.RANGE_LABEL({
							type: localeContent.PUBLISHER_LABEL
						}),
						gridConfig: { md: 5 },
						fieldType: FieldTypes.NUMBER,
						fieldTypeOptions: {
							endAdornmentText: "days"
						},
						defaultValue: 7,
						isRequired: true
					}
				]
			},
			{
				key: "REVENUE DEFAULTS",
				fields: [
					{
						name: "defaultRevShare",
						label: localeContent.DEFAULT_REV_SHARE.LABEL,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.PERCENT,
						fieldTypeOptions: {
							endAdornmentText: "%"
						},
						defaultValue: 85,
						isRequired: true
					},
					{
						name: "defaultMargin",
						label: localeContent.DEFAULT_MARGIN.LABEL,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.PERCENT,
						fieldTypeOptions: {
							endAdornmentText: "%"
						},
						defaultValue: 15,
						isRequired: true
					}
				]
			},
			{
				key: "BITRATE_DEFAULTS",
				fields: [
					{
						name: "minBitrate",
						label: localeContent.MIN_BITRATE.LABEL,
						tooltip: localeContent.MIN_BITRATE.TOOLTIP,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.NUMBER,
						defaultValue: 300,
						isRequired: true
					},
					{
						name: "maxBitrate",
						label: localeContent.MAX_BITRATE.LABEL,
						tooltip: localeContent.MAX_BITRATE.TOOLTIP,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.NUMBER,
						defaultValue: 25000,
						isRequired: true
					}
				]
			},
			{
				key: "IVT DEFAULTS",
				permissionRequired: "VIEW_ALLOW_IVT_FILTERING_FIELD",
				fields: [
					{
						name: "allowIVTFiltering",
						label: localeContent.ALLOW_IVT_FILTERING.LABEL,
						tooltip: localeContent.ALLOW_IVT_FILTERING.TOOLTIP,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							useBooleanValues: true,
							options: [
								{
									value: true,
									label: commonLocaleContent.OPTION_LABEL_ENABLE
								},
								{
									value: false,
									label: commonLocaleContent.OPTION_LABEL_DISABLE
								}
							]
						},
						defaultValue: false
					},
					{
						name: "enforceIVTFiltering",
						label: localeContent.ENFORCE_IVT_FILTERING.LABEL,
						tooltip: localeContent.ENFORCE_IVT_FILTERING.TOOLTIP,
						gridConfig: { md: 4 },
						renderIf: formValues => formValues.allowIVTFiltering,
						fieldType: FieldTypes.RADIO,
						optionsConfig: {
							useBooleanValues: true,
							options: [
								{
									value: true,
									label: commonLocaleContent.OPTION_LABEL_ENABLE
								},
								{
									value: false,
									label: commonLocaleContent.OPTION_LABEL_DISABLE
								}
							]
						},
						defaultValue: false
					},
					{
						name: "ivtProbabilityThreshold",
						label: localeContent.IVT_PROBABILITY_THRESHOLD.LABEL,
						tooltip: localeContent.IVT_PROBABILITY_THRESHOLD.TOOLTIP,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.PERCENT,
						fieldTypeOptions: {
							endAdornmentText: "%"
						},
						defaultValue: 75,
						isRequired: true
					}
				]
			},
			{
				key: "DISPLAY DEFAULTS",
				fields: [
					{
						name: "adResponseMediaLimit",
						label: localeContent.MAX_AD_RESPONSE_AD_SIZE.LABEL,
						tooltip: localeContent.MAX_AD_RESPONSE_AD_SIZE.TOOLTIP,
						gridConfig: { md: 3, xs: 12 },
						fieldType: FieldTypes.NUMBER
					},
					{
						name: "allowServerSideRequests",
						label: localeContent.SERVER_SIDE_REQUEST.LABEL,
						tooltip: localeContent.SERVER_SIDE_REQUEST.TOOLTIP,
						gridConfig: { md: 3, xs: 12 },
						fieldType: FieldTypes.SWITCH,
						defaultValue: false
					},
					{
						name: "suppressDisplayClickthrough",
						permissionRequired: "VIEW_SUPPRESS_DISPLAY_CLICKTHROUGH",
						label: localeContent.SUPPRESS_DISPLAY_CLICKTHROUGH.LABEL,
						tooltip: localeContent.SUPPRESS_DISPLAY_CLICKTHROUGH.TOOLTIP,
						gridConfig: { md: 3 },
						fieldType: FieldTypes.SWITCH,
						defaultValue: false
					}
				]
			},

			{
				key: "MEDIA_AND_PLACEMENT_DEFAULTS",
				fields: [
					{
						name: "mediaPrioritizationType",
						label: localeContent.MEDIA_PRIORITIZATION_TYPE.LABEL,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.RADIO,
						tooltip: localeContent.MEDIA_PRIORITIZATION_TYPE.TOOLTIP,
						optionsConfig: {
							options: [
								{
									value: "PRIORITY",
									label:
										localeContent.MEDIA_PRIORITIZATION_TYPE.OPTIONS.PRIORITY
											.LABEL
								},
								{
									value: "CPM",
									label:
										localeContent.MEDIA_PRIORITIZATION_TYPE.OPTIONS.CPM.LABEL
								}
							]
						},
						defaultValue: "PRIORITY"
					},
					{
						name: "bidderServiceEnabled",
						label: localeContent.BIDDER_SERVICE_ENABLED.LABEL,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.SWITCH,
						defaultValue: false,
						tooltip: localeContent.BIDDER_SERVICE_ENABLED.TOOLTIP
					},
					{
						name: "trafficShapingEnabled",
						label: localeContent.TRAFFIC_SHAPING_ENABLED.LABEL,
						gridConfig: { md: 4 },
						fieldType: FieldTypes.SWITCH,
						defaultValue: false,
						tooltip: localeContent.TRAFFIC_SHAPING_ENABLED.TOOLTIP
					}
				]
			},
			{
				key: "REQUEST MULTIPLIER DEFAULT",
				fields: [
					{
						name: "bidRequestMultiplier",
						label: localeContent.BID_REQUEST_MULTIPLIER.LABEL,
						gridConfig: { md: 6, lg: 4 },
						isRequired: true,
						fieldType: FieldTypes.NUMBER,
						defaultValue: DEFAULT_BID_REQUEST_MULTIPLIER,
						permissionRequired: "EDIT_BID_REQUEST_COST_MULTIPLIER"
					},
					{
						name: "opportunityCostMultiplier",
						label: localeContent.OPPORTUNITY_COST_MULTIPLIER.LABEL,
						gridConfig: { md: 6, lg: 4 },
						fieldType: FieldTypes.NUMBER,
						isRequired: true,
						defaultValue: DEFAULT_OPPORTUNITY_COST_MULTIPLIER,
						permissionRequired: "EDIT_OPPORTUNITY_COST_MULTIPLIER"
					}
				]
			}
		]
	}
];

function CompanyResource() {
	return (
		<CrudForm
			resourceEndpoint="manage/companies"
			resourceRoute="companies"
			resourceString={commonLocaleContent.COMPANY}
			tabsConfig={TABS_CONFIG}
			preSubmit={formValues => {
				const {
					useCustomDomainSettings,
					platformLogo,
					emailLogo,
					favicon,
					...tempValues
				} = formValues;
				const submissionValues = {
					...tempValues,
					defaultRtbMargin: 0
				};
				return submissionValues;
			}}
		/>
	);
}

export default CompanyResource;
