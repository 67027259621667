import React, { useState, useContext } from "react";
import PropTypes from "prop-types";
import { Link } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LoadingBackdrop from "../LoadingBackdrop/LoadingBackdrop";
import ConfirmDialogueSimple from "../ConfirmDialogueSimple/ConfirmDialogueSimple";
import { Operations } from "../../hooks/useResource";
import { SnackbarContext } from "../../context/SnackbarContext";
import localeContent from "./localeContent";
import genericTableRowActionMenuLocaleContent from "../GenericTableRowActionMenu/localeContent";
import { useResourceAsync } from "../../hooks/useResourceAsync";
import useQueryParam from "../../hooks/useQueryParam";

function MediaFileTableRowActionMenu({ data: rows, row, onDeleteRow }) {
	const { id, objectKey, uploadName, previewURL } = row.original;

	const { triggerNewSnackbarMessage } = useContext(SnackbarContext);

	const [anchorEl, setAnchorEl] = React.useState(null);

	const handleClick = event => {
		setAnchorEl(event.currentTarget);
	};

	const closeActionMenu = () => {
		setAnchorEl(null);
	};

	const { isLoading: isDeleting, execute: deleteEntity } = useResourceAsync(
		"manage/mediaAssets",
		Operations.DELETE,
		{
			preserveDataOnExecute: false
		}
	);

	const [isOpenDeleteConfirmation, setIsOpenDeleteConfirmation] = useState(
		false
	);

	const openDeleteConfirmation = () => {
		closeActionMenu();
		setIsOpenDeleteConfirmation(true);
	};

	const closeDeleteConfirmation = () => {
		setIsOpenDeleteConfirmation(false);
	};

	const handleDeleteItem = async () => {
		closeDeleteConfirmation();
		const { isSuccess, error } = await deleteEntity({ resourceId: objectKey });
		if (error) {
			const { message } = error;
			triggerNewSnackbarMessage({
				message,
				severity: "error"
			});
		}
		if (isSuccess) {
			triggerNewSnackbarMessage({
				message: localeContent.DELETE_ASSET_SUCCESS_MESSAGE({
					assetName: uploadName
				}),
				severity: "success"
			});
			onDeleteRow(id);
		}
	};

	const menuId = `table-actions-${objectKey}`;
	const cloneParamValue = useQueryParam("clone");
	const stillCloning = id && id !== "INIT" && Boolean(cloneParamValue);

	return (
		<>
			<IconButton
				aria-label={genericTableRowActionMenuLocaleContent.ACTIONS_BUTTON_LABEL}
				aria-controls={menuId}
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVertIcon />
			</IconButton>
			<Menu
				id={menuId}
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={closeActionMenu}
			>
				<MenuItem
					href={previewURL}
					color="inherit"
					component={Link}
					onClick={closeActionMenu}
					target="_blank"
				>
					{localeContent.ACTION_BUTTON_LABEL_PREVIEW}
					<span className="sr-only-text ">
						({localeContent.ACCESSIBILITY_LABEL_PREVIEW})
					</span>
				</MenuItem>
				{rows.length > 1 && !stillCloning ? (
					<MenuItem onClick={openDeleteConfirmation}>
						{localeContent.ACTION_BUTTON_LABEL_DELETE}
					</MenuItem>
				) : null}
			</Menu>
			<LoadingBackdrop isOpen={isDeleting} testId="delete-asset" />
			<ConfirmDialogueSimple
				title={localeContent.DELETE_ASSET_MODAL.TITLE}
				description={localeContent.DELETE_ASSET_MODAL.DESCRIPTION({
					assetName: uploadName
				})}
				isOpen={isOpenDeleteConfirmation}
				onConfirm={handleDeleteItem}
				confirmLabel={
					localeContent.DELETE_ASSET_MODAL.ACTION_BUTTON_LABEL_CONFIRM
				}
				onCancel={closeDeleteConfirmation}
				onClose={closeDeleteConfirmation}
			/>
		</>
	);
}

MediaFileTableRowActionMenu.propTypes = {
	row: PropTypes.shape().isRequired,
	onDeleteRow: PropTypes.func.isRequired,
	data: PropTypes.arrayOf(PropTypes.shape())
};
MediaFileTableRowActionMenu.defaultProps = {
	data: []
};

export default MediaFileTableRowActionMenu;
